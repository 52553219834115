import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavbarOptions = (props) => {
  const [aboutIsOpen, setAboutIsOpen] = useState(false);
  const [productsIsOpen, setProductsIsOpen] = useState(false);
  const [servicesIsOpen, setServicesIsOpen] = useState(false);
  const [autonomousMonitoringIsOpen, setAutonomousMonitoringIsOpen] =
    useState(false);

  let navigate = useNavigate();

  const { t } = useTranslation();

  const handleHomeClick = () => {
    navigate("/");
  };
  // BLOG
  // const handleBlogClick = () => {
  //   navigate("/blog");
  // };
  const handleContactClick = () => {
    navigate("/contact");
  };

  let navbarOptions = [
    {
      id: "home",
      text: t("navHome"),
      clickHandler: handleHomeClick,
      dropdownContent: null,
    },
    {
      id: "about",
      text: t("navAbout"),
      clickHandler: null,
      dropdownContent: [
        {
          id: "values",
          text: t("navValues"),
          url: "/values",
        },
        {
          id: "team",
          text: t("navTeam"),
          url: "/team",
        },
      ],
    },
    {
      id: "products",
      text: t("navProducts"),
      clickHandler: null,
      dropdownContent: [
        {
          id: "meteo",
          text: t("navMeteo"),
          url: "/meteo",
        },
        {
          id: "trap",
          text: t("navTrap"),
          url: "/trap",
        },
        {
          id: "bee",
          text: t("navBee"),
          url: "/bee",
        },
        {
          id: "sprayer",
          text: t("navSprayer"),
          url: "/sprayer",
        },
      ],
    },
    {
      id: "services",
      text: t("navServices"),
      clickHandler: null,
      dropdownContent: [
        {
          id: "spraying-interoperability",
          text: t("navSI"),
          url: "/spraying-interoperability",
        },
        {
          id: "pest-prediction",
          text: t("navPP"),
          url: "/pest-prediction",
        },
        {
          id: "autonomous-monitoring",
          text: t("navAM"),
          dropdownContent: [
            {
              id: "pests",
              text: t("navPests"),
              url: "/pests",
            },
            {
              id: "beehive",
              text: t("navBeehive"),
              url: "/beehive",
            },
          ],
        },
      ],
    },
    // BLOG
    // {
    //   id: "blog",
    //   text: "Blog",
    //   clickHandler: handleBlogClick,
    //   dropdownContent: null,
    // },
    {
      id: "contact",
      text: t("navContact"),
      clickHandler: handleContactClick,
      dropdownContent: null,
    },
  ];

  const DropdownContent = (props) => {
    return (
      <div className="dropdown-content">
        {props.content.map((option) => (
          <div key={option.id}>
            <Link
              to={option.url}
              id={option.id}
              className="dropdown-option"
              onMouseEnter={
                option.id === "autonomous-monitoring"
                  ? () => setAutonomousMonitoringIsOpen(true)
                  : null
              }
              onMouseLeave={
                option.id === "autonomous-monitoring"
                  ? () => setAutonomousMonitoringIsOpen(false)
                  : null
              }
            >
              <p>{option.text}</p>
              <div className="dropdown-arrow" id={option.id}>
                <span className="arrow-1" />
                <span className="arrow-2" />
              </div>
              {option.id === "autonomous-monitoring"
                ? autonomousMonitoringIsOpen && (
                    <DropdownContent content={option.dropdownContent} />
                  )
                : null}
            </Link>
          </div>
        ))}
      </div>
    );
  };

  const WideNavbarOptions = () => {
    return (
      <div className="navbar-links" id="wide">
        <ul>
          {navbarOptions.map((option) => (
            <div key={option.id} className="navbar-option">
              <li
                onMouseEnter={
                  option.id === "about"
                    ? () => setAboutIsOpen(true)
                    : option.id === "products"
                    ? () => setProductsIsOpen(true)
                    : option.id === "services"
                    ? () => setServicesIsOpen(true)
                    : null
                }
                onMouseLeave={
                  option.id === "about"
                    ? () => setAboutIsOpen(false)
                    : option.id === "products"
                    ? () => setProductsIsOpen(false)
                    : option.id === "services"
                    ? () => setServicesIsOpen(false)
                    : null
                }
              >
                <button onClick={option.clickHandler}>
                  <p>{option.text}</p>
                  <div className="dropdown-arrow" id={option.id}>
                    <span className="arrow-1" />
                    <span className="arrow-2" />
                  </div>
                </button>
                {option.id === "about"
                  ? aboutIsOpen && (
                      <DropdownContent content={option.dropdownContent} />
                    )
                  : option.id === "products"
                  ? productsIsOpen && (
                      <DropdownContent content={option.dropdownContent} />
                    )
                  : option.id === "services"
                  ? servicesIsOpen && (
                      <DropdownContent content={option.dropdownContent} />
                    )
                  : null}
              </li>
              <li id={option.id}>
                <p>|</p>
              </li>
            </div>
          ))}
        </ul>
      </div>
    );
  };

  const CompactNavbarOptions = () => {
    // Separate navbar options into two rows, ones with dropdown menus on the bottom, others on top
    let topNavbarOptions = [];
    let bottomNavbarOptions = [];
    for (let i = 0; i < navbarOptions.length; i++) {
      if (navbarOptions[i].dropdownContent === null) {
        topNavbarOptions.push(navbarOptions[i]);
      } else {
        bottomNavbarOptions.push(navbarOptions[i]);
      }
    }

    return (
      <div className="navbar-options-column-container">
        <div className="navbar-links" id="compact">
          <ul className="top-navbar-options">
            {topNavbarOptions.map((option) => (
              <div key={option.id} className="navbar-option">
                <li>
                  <button onClick={option.clickHandler}>
                    <p>{option.text}</p>
                    <div className="dropdown-arrow" id={option.id}>
                      <span className="arrow-1" />
                      <span className="arrow-2" />
                    </div>
                  </button>
                </li>
                <li id={option.id}>
                  <p>|</p>
                </li>
              </div>
            ))}
          </ul>
        </div>
        <div className="navbar-links" id="compact">
          <ul className="bottom-navbar-options">
            {bottomNavbarOptions.map((option) => (
              <div key={option.id} className="navbar-option">
                <li
                  onMouseEnter={
                    option.id === "about"
                      ? () => setAboutIsOpen(true)
                      : option.id === "products"
                      ? () => setProductsIsOpen(true)
                      : option.id === "services"
                      ? () => setServicesIsOpen(true)
                      : null
                  }
                  onMouseLeave={
                    option.id === "about"
                      ? () => setAboutIsOpen(false)
                      : option.id === "products"
                      ? () => setProductsIsOpen(false)
                      : option.id === "services"
                      ? () => setServicesIsOpen(false)
                      : null
                  }
                >
                  <button onClick={option.clickHandler}>
                    <p>{option.text}</p>
                    <div className="dropdown-arrow" id={option.id}>
                      <span className="arrow-1" />
                      <span className="arrow-2" />
                    </div>
                  </button>
                  {option.id === "about"
                    ? aboutIsOpen && (
                        <DropdownContent content={option.dropdownContent} />
                      )
                    : option.id === "products"
                    ? productsIsOpen && (
                        <DropdownContent content={option.dropdownContent} />
                      )
                    : option.id === "services"
                    ? servicesIsOpen && (
                        <DropdownContent content={option.dropdownContent} />
                      )
                    : null}
                </li>
              </div>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div className="navbar-options-container">
      <WideNavbarOptions />
      {props.type === "navbar" ? <CompactNavbarOptions /> : null}
    </div>
  );
};

export default NavbarOptions;
