import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../../components/Navbar/Navbar";
import Banner from "../../../components/Banner/Banner";
import ItemShowcaseV from "../../../components/ItemShowcaseV/ItemShowcaseV";
import Footer from "../../../components/Footer/Footer";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";

import connectionImage from "../../../images/connection.png";
import banner from "../../../images/sprayer-banner.jpg";

import "./SprayingInteroperability.css";

const SprayingInteroperability = () => {
  const headerRef = useRef(null);
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location]);

  return (
    <div className={`spraying-interoperability ${t("language")}`}>
      <div ref={headerRef} className="header">
        <Navbar />
        <Banner image={banner} text={t("navSI")} />
      </div>
      <ItemShowcaseV image={connectionImage} text={t("siDesc")} />
      <Footer />
      <ScrollToTop reference={headerRef} />
    </div>
  );
};

export default SprayingInteroperability;
