import React from "react";
import { useTranslation } from "react-i18next";

import "./Stats.css";

import farmerImage from "../../images/farmer.png";
import deviceImage from "../../images/smart-farm.png";
import growthImage from "../../images/growth.png";
import fieldImage from "../../images/field.png";

const Stats = () => {
  const { t } = useTranslation();
  const items = [
    {
      id: "farmers",
      image: farmerImage,
      text: t("homeStatsFarmers"),
      value: 2575,
    },
    {
      id: "devices",
      image: deviceImage,
      text: t("homeStatsDevices"),
      value: 26,
    },
    {
      id: "hours",
      image: growthImage,
      text: t("homeStatsHours"),
      value: 2014,
    },
    {
      id: "hectares",
      image: fieldImage,
      text: t("homeStatsHectares"),
      value: 3294,
    },
  ];

  return (
    <div className="stats">
      <div className="stats-list">
        {items.map((item) => (
          <div key={item.id} className="stats-item">
            <img src={item.image} alt="" />
            <p>{`${item.value} ${item.text}`}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stats;
