import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Products.css";

import meteoImage from "../../images/download/meteo.png";
import trapImage from "../../images/download/trap.png";
import beeImage from "../../images/download/bee.jpg";
import sprayerImage from "../../images/download/sprayer.png";
import carouselImage from "../../images/products-carousel.jpg";

const Products = () => {
  const { t } = useTranslation();
  const setBackgroundHeight = () => {
    const productsContainer = document.querySelector(".products-container");
    const background = document.querySelector(".products-background");
    const overlay = document.querySelector(".products-background-overlay");

    if (productsContainer && background && overlay) {
      const height = productsContainer.offsetHeight + "px";
      background.style.height = height;
      overlay.style.height = height;
    }
  };

  // Ensure that the background scales with the content height
  useEffect(() => {
    const productsContainer = document.querySelector(".products-container");

    const resizeObserver = new ResizeObserver((entries) => {
      setBackgroundHeight();
    });

    if (productsContainer) {
      resizeObserver.observe(productsContainer);
    }

    return () => {
      if (productsContainer) {
        resizeObserver.unobserve(productsContainer);
      }
    };
  }, []);

  return (
    <div className="products">
      <img src={carouselImage} alt="" className="products-background" />
      <div className="products-background-overlay"></div>
      <div className="products-container">
        <p className="products-header">{t("navProducts")}</p>
        <ProductsList t={t} />
      </div>
    </div>
  );
};

const ProductsList = (props) => {
  const t = props.t;
  const productsList = [
    {
      id: "meteo",
      name: "Attalos Meteo",
      image: meteoImage,
      bullets: [
        t("homeMeteoP1"),
        t("homeMeteoP2"),
        t("homeMeteoP3"),
        t("homeMeteoP4"),
        t("homeMeteoP5"),
      ],
      buttonURL: "/meteo",
    },
    {
      id: "trap",
      name: "Attalos Trap",
      image: trapImage,
      bullets: [
        t("homeTrapP1"),
        t("homeTrapP2"),
        t("homeTrapP3"),
        t("homeTrapP4"),
        t("homeTrapP5"),
      ],
      buttonURL: "/trap",
    },
    {
      id: "bee",
      name: "Attalos Bee",
      image: beeImage,
      bullets: [
        t("homeBeeP1"),
        t("homeBeeP2"),
        t("homeBeeP3"),
        t("homeBeeP4"),
        t("homeBeeP5"),
      ],
      buttonURL: "/bee",
    },
    {
      id: "sprayer",
      name: "Attalos Sprayer",
      image: sprayerImage,
      bullets: [
        t("homeSprayerP1"),
        t("homeSprayerP2"),
        t("homeSprayerP3"),
        t("homeSprayerP4"),
        t("homeSprayerP5"),
      ],
      buttonURL: "/sprayer",
    },
  ];

  return (
    <div className="products-list">
      {productsList.map((product) => (
        <div key={product.id} className="products-item">
          <div className="products-item-container">
            <p>{product.name}</p>
            <img src={product.image} alt=""></img>
            <ul>
              {product.bullets.map((bullet) => (
                <li key={bullet}>{bullet}</li>
              ))}
            </ul>
            <Link to={product.buttonURL}>
              <button>{t("homeProductsButton")}</button>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Products;
