import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./About.css";

import { ReactComponent as Logo } from "../../images/about-logo.svg";

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="about">
      <p className="about-header">{t("homeAboutHeader")}</p>
      <div className="about-container">
        <Logo />
        <div>
          <p>{t("homeAboutText")}</p>
          {/* MAYBE NOT LINK TO VALUES?? IDK */}
          <Link to="/values">
            <button>{t("homeAboutButton")}</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;
