import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Burger.css";

const Burger = () => {
  const [burgerIsOpen, setBurgerIsOpen] = useState(false);
  const [aboutIsOpen, setAboutIsOpen] = useState(false);
  const [productsIsOpen, setProductsIsOpen] = useState(false);
  const [servicesIsOpen, setServicesIsOpen] = useState(false);
  const [autonomousMonitoringIsOpen, setAutonomousMonitoringIsOpen] =
    useState(false);
  const menuRef = useRef();
  const xButtonRef = useRef();

  const { i18n, t } = useTranslation();
  const handleLanguageChange = (value) => {
    console.log(value);
    i18n.changeLanguage(value);
  };

  const handleBurgerClick = () => {
    if (burgerIsOpen) {
      setBurgerIsOpen(false);
    } else {
      setBurgerIsOpen(true);
    }
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !xButtonRef.current.contains(event.target)
    ) {
      // The click is outside the menuRef element
      setBurgerIsOpen(false);
    }
  };

  const handleAboutClick = () => {
    // CLOSE OTHER DROPDOWNS
    // if (productsIsOpen) {
    //   setProductsIsOpen(false);
    // }
    // if (servicesIsOpen) {
    //   setServicesIsOpen(false);
    // }

    if (aboutIsOpen) {
      setAboutIsOpen(false);
    } else {
      setAboutIsOpen(true);
    }
  };
  const handleProductsClick = () => {
    // CLOSE OTHER DROPDOWNS
    // if (aboutIsOpen) {
    //   setAboutIsOpen(false);
    // }
    // if (servicesIsOpen) {
    //   setServicesIsOpen(false);
    // }

    if (productsIsOpen) {
      setProductsIsOpen(false);
    } else {
      setProductsIsOpen(true);
    }
  };
  const handleServicesClick = () => {
    // CLOSE OTHER DROPDOWNS
    // if (aboutIsOpen) {
    //   setAboutIsOpen(false);
    // }
    // if (productsIsOpen) {
    //   setProductsIsOpen(false);
    // }

    if (servicesIsOpen) {
      setServicesIsOpen(false);
    } else {
      setServicesIsOpen(true);
    }
  };

  const handleAutonomousMonitoringClick = () => {
    if (autonomousMonitoringIsOpen) {
      setAutonomousMonitoringIsOpen(false);
    } else {
      setAutonomousMonitoringIsOpen(true);
    }
  };

  useEffect(() => {
    if (burgerIsOpen) {
      // Disable scrollling
      document.body.style.overflow = "hidden";
    } else {
      // Enable scrollling
      document.body.style.overflow = "visible";
    }
    // Close menu when clicking outside it
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [burgerIsOpen]);

  const aboutDropdown = [
    {
      id: "values",
      text: t("navValues"),
      url: "/values",
    },
    {
      id: "team",
      text: t("navTeam"),
      url: "/team",
    },
  ];

  const productsDropdown = [
    {
      id: "meteo",
      text: t("navMeteo"),
      url: "/meteo",
    },
    {
      id: "trap",
      text: t("navTrap"),
      url: "/trap",
    },
    {
      id: "bee",
      text: t("navBee"),
      url: "/bee",
    },
    {
      id: "sprayer",
      text: t("navSprayer"),
      url: "/sprayer",
    },
  ];

  const servicesDropdown = [
    {
      id: "spraying-interoperability",
      text: t("navSI"),
      url: "/spraying-interoperability",
      dropdownContent: null,
    },
    {
      id: "pest-prediction",
      text: t("navPP"),
      url: "/pest-prediction",
      dropdownContent: null,
    },
    {
      id: "autonomous-monitoring",
      text: t("navAM"),
      url: null,
      dropdownContent: [
        {
          id: "pests",
          text: t("navPests"),
          url: "/pests",
        },
        {
          id: "beehive",
          text: t("navBeehive"),
          url: "/beehive",
        },
      ],
    },
  ];

  return (
    <div className={`navbar-burger ${burgerIsOpen ? "active" : ""}`}>
      <button
        ref={xButtonRef}
        className="burger-button"
        onClick={handleBurgerClick}
      >
        <span id="burger-1" />
        <span id="burger-2" />
        <span id="burger-3" />
      </button>
      <div className="burger-background-mask" />
      <div ref={menuRef} className="burger-menu">
        <ul className="burger-menu-list">
          <li>
            <Link to="/">{t("navHome")}</Link>
          </li>
          <li>
            <button onClick={handleAboutClick}>
              {t("navAbout")}
              <div className="dropdown-arrow">
                <span className="arrow-1" />
                <span className="arrow-2" />
              </div>
            </button>
            {aboutIsOpen && (
              <ul className="burger-dropdown-list-1">
                {aboutDropdown.map((option) => (
                  <li key={option.id}>
                    <Link to={option.url}>{option.text}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
          <li>
            <button onClick={handleProductsClick}>
              {t("navProducts")}
              <div className="dropdown-arrow">
                <span className="arrow-1" />
                <span className="arrow-2" />
              </div>
            </button>
            {productsIsOpen && (
              <ul className="burger-dropdown-list-1">
                {productsDropdown.map((option) => (
                  <li key={option.id}>
                    <Link to={option.url}>{option.text}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
          <li>
            <button onClick={handleServicesClick}>
              {t("navServices")}
              <div className="dropdown-arrow">
                <span className="arrow-1" />
                <span className="arrow-2" />
              </div>
            </button>
            {servicesIsOpen && (
              <ul className="burger-dropdown-list-1">
                {servicesDropdown.map((option) => (
                  <li key={option.id}>
                    {option.id === "autonomous-monitoring" ? (
                      <div>
                        <button onClick={handleAutonomousMonitoringClick}>
                          {option.text}
                          <div className="dropdown-arrow">
                            <span className="arrow-1" />
                            <span className="arrow-2" />
                          </div>
                        </button>
                        {autonomousMonitoringIsOpen && (
                          <ul className="burger-dropdown-list-2">
                            {option.dropdownContent.map((option) => (
                              <li key={option.id}>
                                <Link to={option.url}>{option.text}</Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ) : (
                      <Link to={option.url}>{option.text}</Link>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
          <li>
            <Link to="/contact">{t("navContact")}</Link>
          </li>
        </ul>
        <span className="burger-divider" />
        <ul className="burger-menu-list" id="bottom">
          <li>
            <button onClick={() => handleLanguageChange(t("otherLanguage"))}>
              {t("otherLanguage") === "en" ? "English" : "Ελληνικά"}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Burger;
