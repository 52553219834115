import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../../components/Navbar/Navbar";
import Banner from "../../../components/Banner/Banner";
import LargeList from "../../../components/LargeList/LargeList";
import AlternatingTextImageList from "../../../components/AlternatingTextImageList/AlternatingTextImageList";
import Footer from "../../../components/Footer/Footer";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";

import banner from "../../../images/values-banner.jpg";
import innovationImage from "../../../images/innovation.png";
import sustainabilityImage from "../../../images/sustainability.png";
import qualityImage from "../../../images/quality.png";
import collaborationImage from "../../../images/collaboration.png";
import impactImage from "../../../images/impact.png";

import "./Values.css";

const Values = () => {
  const headerRef = useRef(null);
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location]);

  const shortValues = [
    {
      index: 1,
      shortText: t("values1"),
    },
    {
      index: 2,
      shortText: t("values2"),
    },
    {
      index: 3,
      shortText: t("values3"),
    },
    {
      index: 4,
      shortText: t("values4"),
    },
    {
      index: 5,
      shortText: t("values5"),
    },
  ];
  // const shortValues = [
  //   {
  //     index: 1,
  //     shortText:
  //       "Innovation: Our products are at the forefront of agritech innovation, providing farmers with cutting-edge solutions to pest control and beehive monitoring that are reliable and effective.",
  //   },
  //   {
  //     index: 2,
  //     shortText:
  //       "Sustainability: We are committed to sustainable agriculture practices, which is why our pest control products are designed to reduce chemical use, while our beehive monitoring devices promote healthier, more productive bee colonies.",
  //   },
  //   {
  //     index: 3,
  //     shortText:
  //       "Quality: We are dedicated to delivering products of the highest quality, with rigorous testing and quality control measures in place to ensure that every product we produce meets our high standards.",
  //   },
  //   {
  //     index: 4,
  //     shortText:
  //       "Collaboration: We work closely with farmers to understand their needs and develop products that are tailored to their specific requirements, ensuring that our solutions are always effective and user-friendly.",
  //   },
  //   {
  //     index: 5,
  //     shortText:
  //       "Impact: Our products are designed to have a positive impact on the environment and the farming industry as a whole, promoting sustainable practices and helping farmers to achieve greater yields and profitability.",
  //   },
  // ];

  const values = [
    {
      index: 1,
      image: innovationImage,
      title: t("values1"),
      description: t("values1Desc"),
    },
    {
      index: 2,
      image: sustainabilityImage,
      title: t("values2"),
      description: t("values2Desc"),
    },
    {
      index: 3,
      image: qualityImage,
      title: t("values3"),
      description: t("values3Desc"),
    },
    {
      index: 4,
      image: collaborationImage,
      title: t("values4"),
      description: t("values4Desc"),
    },
    {
      index: 5,
      image: impactImage,
      title: t("values5"),
      description: t("values5Desc"),
    },
  ];

  return (
    <div className={`values ${t("language")}`}>
      <div ref={headerRef} className="header">
        <Navbar />
        <Banner image={banner} text={t("navValues")} />
      </div>
      {/* <ItemShowcaseListH list={shortValues} image={placeholder} /> */}
      <LargeList list={shortValues} />
      <AlternatingTextImageList list={values} />
      <Footer />
      <ScrollToTop reference={headerRef} />
    </div>
  );
};

export default Values;
