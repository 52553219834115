import React from 'react'

import './Blog.css'

const Blog = () => {
  return (
    <div className='blog'>Blog</div>
  )
}

export default Blog