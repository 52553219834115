import React from "react";
import { useTranslation } from "react-i18next";

import "./ContactDetails.css";

const ContactDetails = () => {
  const { t } = useTranslation();

  const telephone = "+30-281-301-3122";
  const email = "info@optisol.io";
  const address1 = t("contactAddress1");
  const address2 = "71306";
  const address3 = t("contactAddress3");
  const paragraph = t("contactText");
  return (
    <div className="contact-details">
      <div className="contact-details-column">
        <div className="contact-telephone">
          <i className="fa-solid fa-phone" />
          <p>
            {t("contactTelephone")}
            <br />
            {telephone}
          </p>
        </div>
        <div className="contact-email">
          <i className="fa-solid fa-envelope" />
          <p>
            Email
            <br />
            {email}
          </p>
        </div>
        <div className="contact-address">
          <i className="fa-solid fa-building" />
          <p>
            {t("contactAddress")}
            <br />
            {address1}
            <br />
            {address2}
            <br />
            {address3}
          </p>
        </div>
      </div>
      <div className="contact-details-text">
        <p>{paragraph}</p>
      </div>
    </div>
  );
};

export default ContactDetails;
