import React from "react";

import "./AlternatingTextImageList.css";

const AlternatingTextImageList = (props) => {
  return (
    <div className="alt-list">
      <span className="alt-list-transition-top" />
      <ul>
        {props.list.map((item) => {
          if (item.index % 2 === 1) {
            return (
              <li className="alt-list-item" key={item.index}>
                <div className="item-text-container">
                  <h1>{`${item.index}) ${item.title}`}</h1>
                  <p>{item.description}</p>
                </div>
                <div className="item-image-container">
                  <img src={item.image} alt="" />
                </div>
              </li>
            );
          } else {
            return (
              <li className="alt-list-item" key={item.index}>
                <div className="item-image-container">
                  <img src={item.image} alt="" />
                </div>
                <div className="item-text-container">
                  <h1>{`${item.index}) ${item.title}`}</h1>
                  <p>{item.description}</p>
                </div>
              </li>
            );
          }
        })}
      </ul>
      <span className="alt-list-transition-bottom" />
    </div>
  );
};

export default AlternatingTextImageList;
