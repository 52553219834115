import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./Services.css";

import climateImage from "../../images/climate.png";
import bugImage from "../../images/bug.png";
import sprayingImage from "../../images/watering-sprayer.png";

const Services = () => {
  const { t } = useTranslation();
  const setBackgroundHeight = () => {
    const servicesContainer = document.querySelector(".services-container");
    const svg = document.querySelector(".services-svg");

    if (servicesContainer && svg) {
      const height = servicesContainer.offsetHeight + "px";
      svg.style.height = height;
    }
  };
  const services = [
    {
      id: "auto",
      title: t("homeServicesAM"),
      description: t("homeServicesAMDesc"),
      image: climateImage,
    },
    {
      id: "pest",
      title: t("homeServicesPP"),
      description: t("homeServicesPPDesc"),
      image: bugImage,
    },
    {
      id: "spray",
      title: t("homeServicesSI"),
      description: t("homeServicesSIDesc"),
      image: sprayingImage,
    },
  ];

  // Ensure that the background scales with the content height
  useEffect(() => {
    const servicesContainer = document.querySelector(".services-container");

    const resizeObserver = new ResizeObserver((entries) => {
      setBackgroundHeight();
    });

    if (servicesContainer) {
      resizeObserver.observe(servicesContainer);
    }

    return () => {
      if (servicesContainer) {
        resizeObserver.unobserve(servicesContainer);
      }
    };
  }, []);

  return (
    <div className="services">
      <svg className="services-svg">
        <defs>
          <filter id="f1" x="-10%" y="-10%">
            <feGaussianBlur in="SourceGraphic" stdDeviation="15" />
          </filter>
          <filter id="f2" x="-10%" y="-10%">
            <feGaussianBlur in="SourceGraphic" stdDeviation="25" />
          </filter>
        </defs>
        <circle
          cx="120%"
          cy="-146%"
          r="3300"
          stroke="#62810080"
          strokeWidth="80"
          fill="none"
          filter="url(#f1)"
        />
        <circle
          cx="120%"
          cy="130%"
          r="1500"
          stroke="#2c4c0140"
          strokeWidth="120"
          fill="none"
          filter="url(#f2)"
        />
      </svg>
      <div className="services-container">
        <p className="services-header">{t("navServices")}</p>
        <div className="services-list">
          {services.map((service) => (
            <div key={service.id} className="service-card" id={service.id}>
              <p className="service-title">{service.title}</p>
              <img src={service.image} alt="" />
              <p className="service-description">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
