import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../../components/Navbar/Navbar";
import Banner from "../../../components/Banner/Banner";
import ItemShowcaseV from "../../../components/ItemShowcaseV/ItemShowcaseV";
import AlternatingTextImageList from "../../../components/AlternatingTextImageList/AlternatingTextImageList";
import ServicesReference from "../../../components/ServicesReference/ServicesReference";
import Footer from "../../../components/Footer/Footer";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";

import trapImage from "../../../images/download/trap-x2-post.png";
import banner from "../../../images/trap-banner.jpg";
import benefit1 from "../../../images/bug.png";
import benefit2 from "../../../images/shield.png";
import benefit3 from "../../../images/future.png";
import benefit4 from "../../../images/sustainability.png";
import service1 from "../../../images/climate.png";
import service2 from "../../../images/bug.png";

import "./Trap.css";

const Trap = () => {
  const headerRef = useRef(null);
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location]);

  const benefits = [
    {
      index: 1,
      image: benefit1,
      title: t("trapBenefit1"),
      description: t("trapBenefit1Desc"),
    },
    {
      index: 2,
      image: benefit2,
      title: t("trapBenefit2"),
      description: t("trapBenefit2Desc"),
    },
    {
      index: 3,
      image: benefit3,
      title: t("trapBenefit3"),
      description: t("trapBenefit3Desc"),
    },
    {
      index: 4,
      image: benefit4,
      title: t("trapBenefit4"),
      description: t("trapBenefit4Desc"),
    },
  ];

  const services = [
    {
      index: 1,
      image: service1,
      title: t("amPestsBanner"),
      description: t("amPestsDesc"),
    },
    {
      index: 2,
      image: service2,
      title: t("navPP"),
      description: t("ppDesc"),
    },
  ];

  return (
    <div className={`trap ${t("language")}`}>
      <div ref={headerRef} className="header">
        <Navbar />
        <Banner image={banner} text="Attalos Trap" />
      </div>
      <ItemShowcaseV image={trapImage} text={t("trapDesc")} />
      <AlternatingTextImageList list={benefits} />
      <ServicesReference services={services} />
      <Footer />
      <ScrollToTop reference={headerRef} />
    </div>
  );
};

export default Trap;
