import React from "react";

import "./ScrollToTop.css";

const ScrollToTop = (props) => {
  const handleClick = () => {
    if (props.reference.current) {
      props.reference.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  return (
    <button className="top-scroll" onClick={handleClick}>
      <div className="top-scroll-arrow">
        <span className="top-scroll-arrow-1" />
        <span className="top-scroll-arrow-2" />
      </div>
    </button>
  );
};

export default ScrollToTop;
