import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../../components/Navbar/Navbar";
import Banner from "../../../components/Banner/Banner";
import TeamShowcase from "../../../components/TeamShowcase/TeamShowcase";
import Footer from "../../../components/Footer/Footer";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";

import banner from "../../../images/team-banner.jpg";

import "./Team.css";

const Team = () => {
  const headerRef = useRef(null);
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location]);

  return (
    <div className={`team ${t("language")}`}>
      <div ref={headerRef} className="header">
        <Navbar />
        <Banner image={banner} text={t("navTeam")} />
      </div>
      <TeamShowcase text={t("teamDesc")} />
      <Footer />
      <ScrollToTop reference={headerRef} />
    </div>
  );
};

export default Team;
