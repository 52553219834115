import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../../components/Navbar/Navbar";
import Banner from "../../../components/Banner/Banner";
import ItemShowcaseV from "../../../components/ItemShowcaseV/ItemShowcaseV";
import AlternatingTextImageList from "../../../components/AlternatingTextImageList/AlternatingTextImageList";
import ServicesReference from "../../../components/ServicesReference/ServicesReference";
import Footer from "../../../components/Footer/Footer";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";

import beeImage from "../../../images/download/bee.jpg";
import banner from "../../../images/bee-banner.jpg";
import benefit1 from "../../../images/productivity.png";
import benefit2 from "../../../images/save-time.png";
import benefit3 from "../../../images/bee-box.png";
import service1 from "../../../images/hive.png";

import "./Bee.css";

const Bee = () => {
  const headerRef = useRef(null);
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location]);

  const benefits = [
    {
      index: 1,
      image: benefit1,
      title: t("beeBenefit1"),
      description: t("beeBenefit1Desc"),
    },
    {
      index: 2,
      image: benefit2,
      title: t("beeBenefit2"),
      description: t("beeBenefit2Desc"),
    },
    {
      index: 3,
      image: benefit3,
      title: t("beeBenefit3"),
      description: t("beeBenefit3Desc"),
    },
  ];

  const services = [
    {
      index: 1,
      image: service1,
      title: t("amBeehiveBanner"),
      description: t("amBeehiveDesc"),
    },
  ];

  return (
    <div className={`bee ${t("language")}`}>
      <div ref={headerRef} className="header">
        <Navbar />
        <Banner image={banner} text="Attalos Bee" />
      </div>
      <ItemShowcaseV image={beeImage} />
      <AlternatingTextImageList list={benefits} />
      <ServicesReference services={services} />
      <Footer />
      <ScrollToTop reference={headerRef} />
    </div>
  );
};

export default Bee;
