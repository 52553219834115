import React from "react";
import { Link } from "react-router-dom";

import "./Footer.css";

import logo from "../../images/logo.svg";

// import NavbarOptions from "../Navbar/NavbarOptions";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-bottom">
        <Link to="/" className="footer-logo">
          <img src={logo} alt="" />
        </Link>
        <div className="footer-privacy">
          <Link to="/privacy">
            <button>Privacy Policy</button>
          </Link>
          <p>
            <span>&nbsp;|</span> Copyright © 2022 All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
