import { t } from "i18next";
import React from "react";

import "./ServicesReference.css";

const ServicesReference = (props) => {
  return (
    <div className="services-reference">
      <h1>{t("navServices")}</h1>
      <ul>
        {props.services.map((service) => (
          <div key={service.index} className="services-reference-item">
            <div className="service-image-title-container">
              <img src={service.image} alt="" />
              <h2>{service.title}</h2>
            </div>
            <div className="service-text-container">
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default ServicesReference;
