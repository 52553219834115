import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../../../components/Navbar/Navbar";
import Banner from "../../../../components/Banner/Banner";
import ItemShowcaseV from "../../../../components/ItemShowcaseV/ItemShowcaseV";
import Footer from "../../../../components/Footer/Footer";
import ScrollToTop from "../../../../components/ScrollToTop/ScrollToTop";

import climateImage from "../../../../images/climate.png";
import banner from "../../../../images/trap-banner.jpg";

import "./Pests.css";

const Pests = () => {
  const headerRef = useRef(null);
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location]);

  return (
    <div className={`am-pests ${t("language")}`}>
      <div ref={headerRef} className="header">
        <Navbar />
        <Banner image={banner} text={t("amPestsBanner")} />
      </div>
      <ItemShowcaseV image={climateImage} text={t("amPestsDesc")} />
      <Footer />
      <ScrollToTop reference={headerRef} />
    </div>
  );
};

export default Pests;
