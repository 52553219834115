import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../components/Navbar/Navbar";
import Banner from "../../components/Banner/Banner";
import ContactDetails from "../../components/ContactDetails/ContactDetails";
// import LocationOnMap from "../../components/LocationOnMap/LocationOnMap";
import Footer from "../../components/Footer/Footer";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

import banner from "../../images/contact-banner.jpg";

import "./Contact.css";

const Contact = () => {
  const headerRef = useRef(null);
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location]);

  return (
    <div className={`contact ${t("language")}`}>
      <div ref={headerRef} className="header">
        <Navbar />
        <Banner image={banner} text={t("navContact")} />
      </div>
      <ContactDetails />
      {/* <LocationOnMap /> */}
      <Footer />
      <ScrollToTop reference={headerRef} />
    </div>
  );
};

export default Contact;
