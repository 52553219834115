import React from "react";

import "./LargeList.css";

const LargeList = (props) => {
  return (
    <div className="large-list">
      <div className="large-list-container">
        <ol>
          {props.list.map((item) => (
            <li key={item.index}>{item.shortText}</li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default LargeList;
