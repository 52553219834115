import React from "react";

// import placeholder from "../../images/placeholder.png";

import "./TeamShowcase.css";

const TeamShowcase = (props) => {
  const paragraph = props.text;
  // const team = [
  //   {
  //     id: 1,
  //     image: placeholder,
  //     name: "full name",
  //     position: "position",
  //   },
  //   {
  //     id: 2,
  //     image: placeholder,
  //     name: "full name",
  //     position: "position",
  //   },
  //   {
  //     id: 3,
  //     image: placeholder,
  //     name: "full name",
  //     position: "position",
  //   },
  //   {
  //     id: 4,
  //     image: placeholder,
  //     name: "full name",
  //     position: "position",
  //   },
  // ];
  return (
    <div className="team-showcase">
      <p>{paragraph}</p>
      {/* <div className="team-list">
        {team.map((member) => (
          <div className="team-member">
            <img src={placeholder} alt="" />
            <p>{member.name}</p>
            <p>{member.position}</p>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default TeamShowcase;
