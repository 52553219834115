import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Partners.css";

import prasiniIKE from "../../images/partner logos/prasiniIKE.png";
import koytsoyras from "../../images/partner logos/koytsoyras.png";
import mylopotamos from "../../images/partner logos/mylopotamos.png";
import kritsaacoop from "../../images/partner logos/kritsaacoop.png";
import asoreinou from "../../images/partner logos/asoreinou.png";
import famelia from "../../images/partner logos/famelia.svg";
import agrunion from "../../images/partner logos/agrunion.png";
import agrofarm from "../../images/partner logos/agrofarm.jpg";

const Partners = () => {
  const { t } = useTranslation();
  const partners = [
    {
      id: "prasiniike",
      logo: prasiniIKE,
      url: "http://www.prasini-ike.gr/",
    },
    {
      id: "koytsoyras",
      logo: koytsoyras,
      url: "https://www.koutsourascoop.gr/",
    },
    {
      id: "mylopotamos",
      logo: mylopotamos,
      url: "https://mylopotamos.gr/",
    },
    {
      id: "kritsaacoop",
      logo: kritsaacoop,
      url: "https://kritsacoop.gr/",
    },
    {
      id: "asoreinou",
      logo: asoreinou,
      url: "https://asoreinou.gr/",
    },
    {
      id: "famelia",
      logo: famelia,
      url: "https://www.famelia-asterousion.gr/",
    },
    {
      id: "agrunion",
      logo: agrunion,
      url: "https://www.agrunion.gr/",
    },
    {
      id: "agrofarm",
      logo: agrofarm,
      url: "https://www.creteagrofarm.gr/",
    },
  ];

  return (
    <div className="partners">
      <span className="partners-transition" />
      <p className="partners-header">{t("homePartners")}</p>
      <div className="partners-list">
        {partners.map((partner) => (
          <Link key={partner.id} to={partner.url}>
            <div className="partner-card">
              <img src={partner.logo} alt="" />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Partners;
