import React from "react";

import "./ItemShowcaseV.css";

const ItemShowcaseV = (props) => {
  return (
    <div className="item-showcase-v">
      <img src={props.image} alt="" />
      <p>{props.text}</p>
    </div>
  );
};

export default ItemShowcaseV;
