import "./App.css";
import "./fonts/Mosafin/Mosafin-Medium.css";
import "./fonts/Mosafin/Mosafin-Black.css";
import "./fonts/Mosafin/Mosafin-Bold.css";
import "./fonts/Mosafin/Mosafin-ExtraBold.css";
import "./fonts/Mosafin/Mosafin-SemiBold.css";

import "./i18n"; // Import the i18n configuration

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { HomePage } from "./pages/HomePage/HomePage";
// Products
import Meteo from "./pages/Products/Meteo/Meteo";
import Trap from "./pages/Products/Trap/Trap";
import Bee from "./pages/Products/Bee/Bee";
import Sprayer from "./pages/Products/Sprayer/Sprayer";
// About
import Values from "./pages/About/Values/Values";
import Team from "./pages/About/Team/Team";
// Services
import SprayingInteroperability from "./pages/Services/SprayingInteroperability/SprayingInteroperability";
import PestPrediction from "./pages/Services/PestPrediction/PestPrediction";
import Beehive from "./pages/Services/AutonomousMonitoring/Beehive/Beehive";
import Pests from "./pages/Services/AutonomousMonitoring/Pests/Pests";

// Misc
import Blog from "./pages/Blog/Blog";
import Contact from "./pages/Contact/Contact";
import Privacy from "./pages/Privacy/Privacy";

function App() {
  return (
    <BrowserRouter className="App">
      <Routes>
        {/* <Route path="/" element={<Navigate replace to="/en" />} />
        <Route path="/en" element={<HomePage />} />
        <Route path="/en/meteo" element={<Meteo />} />
        <Route path="/en/trap" element={<Trap />} />
        <Route path="/en/bee" element={<Bee />} />
        <Route path="/en/sprayer" element={<Sprayer />} />
        <Route path="/en/values" element={<Values />} />
        <Route path="/en/team" element={<Team />} />
        <Route path="/en/blog" element={<Blog />} />
        <Route path="/en/contact" element={<Contact />} />
        <Route path="/en/privacy" element={<Privacy />} />
        <Route
          path="/en/spraying-interoperability"
          element={<SprayingInteroperability />}
        />
        <Route path="/en/pest-prediction" element={<PestPrediction />} />
        <Route path="/en/pests" element={<Pests />} />
        <Route path="/en/beehive" element={<Beehive />} /> */}

        <Route path="/" element={<HomePage />} />
        <Route path="/meteo" element={<Meteo />} />
        <Route path="/trap" element={<Trap />} />
        <Route path="/bee" element={<Bee />} />
        <Route path="/sprayer" element={<Sprayer />} />
        <Route path="/values" element={<Values />} />
        <Route path="/team" element={<Team />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route
          path="/spraying-interoperability"
          element={<SprayingInteroperability />}
        />
        <Route path="/pest-prediction" element={<PestPrediction />} />
        <Route path="/pests" element={<Pests />} />
        <Route path="/beehive" element={<Beehive />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
