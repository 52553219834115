import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../../components/Navbar/Navbar";
import Banner from "../../../components/Banner/Banner";
import ItemShowcaseV from "../../../components/ItemShowcaseV/ItemShowcaseV";
import AlternatingTextImageList from "../../../components/AlternatingTextImageList/AlternatingTextImageList";
import Footer from "../../../components/Footer/Footer";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";

import banner from "../../../images/meteo-banner.jpg";
import meteoImage from "../../../images/download/meteo-x2.png";
import benefit1 from "../../../images/drone.png";
import benefit2 from "../../../images/watering-can.png";
import benefit3 from "../../../images/watering.png";

import "./Meteo.css";

const Meteo = () => {
  const headerRef = useRef(null);
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location]);

  const benefits = [
    {
      index: 1,
      image: benefit1,
      title: t("meteoBenefit1"),
      description: t("meteoBenefit1Desc"),
    },
    {
      index: 2,
      image: benefit2,
      title: t("meteoBenefit2"),
      description: t("meteoBenefit2Desc"),
    },
    {
      index: 3,
      image: benefit3,
      title: t("meteoBenefit2"),
      description: t("meteoBenefit3Desc"),
    },
  ];

  return (
    <div className={`meteo ${t("language")}`}>
      <div ref={headerRef} className="header">
        <Navbar />
        <Banner image={banner} text="Attalos Meteo" />
      </div>
      <ItemShowcaseV image={meteoImage} text={t("meteoDesc")} />
      <AlternatingTextImageList list={benefits} />
      <Footer />
      <ScrollToTop reference={headerRef} />
    </div>
  );
};

export default Meteo;
