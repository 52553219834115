import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../../../components/Navbar/Navbar";
import Banner from "../../../../components/Banner/Banner";
import ItemShowcaseV from "../../../../components/ItemShowcaseV/ItemShowcaseV";
import Footer from "../../../../components/Footer/Footer";
import ScrollToTop from "../../../../components/ScrollToTop/ScrollToTop";

import banner from "../../../../images/bee-banner.jpg";
import hiveImage from "../../../../images/hive.png";

import "./Beehive.css";

const Beehive = () => {
  const headerRef = useRef(null);
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location]);

  return (
    <div className={`am-beehive ${t("language")}`}>
      <div ref={headerRef} className="header">
        <Navbar />
        <Banner image={banner} text={t("amBeehiveBanner")} />
      </div>
      <ItemShowcaseV image={hiveImage} text={t("amBeehiveDesc")} />
      <Footer />
      <ScrollToTop reference={headerRef} />
    </div>
  );
};

export default Beehive;
