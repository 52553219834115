import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../components/Navbar/Navbar";
import Banner from "../../components/Banner/Banner";
import About from "../../components/About/About";
import Products from "../../components/Products/Products";
import Stats from "../../components/Stats/Stats";
import Services from "../../components/Services/Services";
import Partners from "../../components/Partners/Partners";
import Blog from "../../components/Blog/Blog";
import Footer from "../../components/Footer/Footer";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

import banner from "../../images/banner.jpg";

import "./HomePage.css";

export const HomePage = () => {
  const headerRef = useRef(null);
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location]);

  const bannerText = t("homeBannerText");

  return (
    <div className={`homepage ${t("language")}`}>
      <div ref={headerRef} className="header">
        <Navbar></Navbar>
        <Banner image={banner} text={bannerText}></Banner>
      </div>
      <About></About>
      <Products></Products>
      <Stats></Stats>
      <Services></Services>
      <Partners></Partners>
      <Blog></Blog>
      <Footer></Footer>
      <ScrollToTop reference={headerRef} />
    </div>
  );
};
