import React from "react";

import "./Banner.css";

const Banner = (props) => {
  return (
    <div className="banner">
      <img src={props.image} alt="" className="banner-image"></img>{" "}
      <div className="banner-image-overlay" />
      <div
        className={`banner-text-container ${props.text ? "" : "display-none"}`}
      >
        <p>{props.text}</p>
      </div>
      {/* <BannerImage className="banner-image" /> */}
    </div>
  );
};

export default Banner;
